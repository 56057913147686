import React, { useState } from 'react';

import SalesPartner from './SalesPartner';

function SalesPartnerAccordion({
  country,
  state,
  partnerList,
}) {

  const [showPartners, setShowPartners] = useState('Show partners');
  const [accordion, setAccordion] = useState('accordion-hide');
  const [rotate, setRotate] = useState('');

  const handleAccordion = ()  => {
    setShowPartners(showPartners === 'Show partners' ? 'Hide partners' : 'Show partners');
    setAccordion(accordion === '' ? 'accordion-hide' : '');
    setRotate(rotate === '' ? 'rotate-45' : '');
  };

  return (
    <>
      {partnerList[country.slug][state.value].partners.length > 0 &&
        <>
          <div className="accordion-header" onClick={handleAccordion} >
            <div className="state-name">{state.label}</div>
            <div className={`view-partners ${rotate}`}>{showPartners}</div>
          </div>
          {partnerList && partnerList[country.slug][state.value].partners.map((partner) => {
            return (
              <div key={partner.partner} className={`partner-item accordion-body ${accordion}`}>
                <SalesPartner partner={partner} />
              </div>
            )
          })}
        </>
      }
    </>
  )
}

export default SalesPartnerAccordion;