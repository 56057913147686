import React from 'react';
import {
  salesPartnerTypes,
  salesPartnerProducts
} from '../resources/valueLists';

export default function SalesPartnerFilters({
  countryList,
  disabled,
  setSelectCountry,
  setSelectService,
  setSelectProduct,
}) {

  return (
    <div className="sales-partners-selects">
      <select
        id="country-select"
        onChange={(e) => setSelectCountry(e.target.value)}
        disabled={disabled}
      >
        <option value="">Choose country</option>
        {countryList.map(country =>
          <option
            key={country.slug}
            value={country.slug}
          >
            {country.name}
          </option>
        )}
      </select>
      <select
        id="service-select"
        onChange={(e) => setSelectService(e.target.value)}
        disabled={disabled}
      >
        <option value="">Choose partner type</option>
        {salesPartnerTypes.map(type =>
          <option
            key={type.value}
            value={type.value}
          >
            {type.label}
          </option>
        )};
      </select>
      <select
        id="product-select"
        onChange={(e) => setSelectProduct(e.target.value)}
        disabled={disabled}
      >
        <option value="">Choose product type</option>
        {salesPartnerProducts.map(product =>
          <option
            key={product.value}
            value={product.value}
          >
            {product.label}
          </option>
        )}
      </select>
    </div>
  );
};
