import React from 'react';

export default function SalesPartner({ partner }) {
  return (
    <div className="sales-partner">
      <div className="sales-partner__name">{partner.partner}</div>
      <div className="sales-partner__service">
        {partner.country.service.map(service =>
          <div key={service}>{service}</div>
        )}
      </div>
      <div className="sales-partner__product">
        {partner.country.products.map(product =>
          <div key={product}>{product}</div>
        )}
      </div>
      <div className="sales-partner__link">
        <button className="btn btn-outline-primary global">
          Partner website
        </button>
        <a
          className="sales-partners-link"
          href={partner.website}
          target="_blank"
          rel="noopener noreferrer"
        > </a>
      </div>
    </div>
  );
};
