import React from 'react';

import SalesPartner from './SalesPartner';
import SalesPartnerAccordion from './SalesPartnerAccordion';

import { usStates } from '../resources/valueLists';

function SalesPartnerList({
  countryList,
  partnerList,
  isError,
  isLoading,
  selectCountry,
}) {

  // Provide the display name of the country
  const getCountryName = (slug) => {
    const country = countryList.find(country => country.slug === slug);
    return country.name;
  }

  return (
    <>
      {isError &&
        <>
          <div>There was an error when fetching the sales partners. Please, try again later.</div>
          <div>Error: {isError}</div>
        </>
      }
      {isLoading &&
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      }
      {/* If the country filter is active render the selected country */}
      {!isLoading && selectCountry && Object.keys(partnerList).length > 0 &&
        <div key={selectCountry} className="country-section">
          <h2>{getCountryName(selectCountry)}</h2>
          {/* Other countries have no states under the country */}
          {selectCountry !== 'unitedstatesofamerica'
            ? partnerList[selectCountry].partners.length > 0
              ? partnerList[selectCountry].partners.map((partner) => {
                return (
                  <SalesPartner key={partner.partner} partner={partner} />
                );
              })
              : <div>No sales partners in this country.</div>
            : partnerList && usStates.map(state => {
              return (
                <SalesPartnerAccordion
                  country={{ label: 'United States Of America', slug: 'unitedstatesofamerica' }}
                  state={state}
                  partnerList={partnerList}
                />
              )
            })
          }
      </div>
      }
      {/* If the country filter is not active render all countries */}
      {!isLoading && selectCountry === '' && Object.keys(partnerList).length > 0 &&
        countryList.map(country => {
          if (country.slug !== 'unitedstatesofamerica' &&
            partnerList[country.slug].partners.length > 0) {
              return (
                <div key={country.name} className="country-section">
                  <h2>{country.name}</h2>

                  {partnerList && partnerList[country.slug].partners.map((partner) => {
                    return (
                      <SalesPartner key={partner.partner} partner={partner} />
                    )
                  })}
                </div>
              )
          } else if (country.slug === 'unitedstatesofamerica' && // USA has states under countries and sales partners per state
            partnerList[country.slug].count > 0) {
              return (
                <div key={country.name} className="country-section">
                  <h2>{country.name}</h2>
                  {usStates.map(state => {
                    return (
                      <SalesPartnerAccordion
                        key={state.value}
                        country={country}
                        state={state}
                        partnerList={partnerList}
                      />
                    )
                  })}
                </div>
              )
            } else {
            return null;
          }
        })
      }
    </>
  );
};

export default SalesPartnerList;