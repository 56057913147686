import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { usStates } from './resources/valueLists';

import SalesPartnerFilters from './components/SalesPartnerFilters';
import SalesPartnerList from './components/SalesPartnerList';

// Variable for storing the sales partner data during the parsing operation
let partnerCountries = [];
let localPartnerList = {};

function App() {
  // Lists of countries and sales partner data
  const [countryList, setCountryList] = useState([]);
  const [partnerList, setPartnerList] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState('');
  // States for the filters in SalesPartnerFilters.js
  const [selectCountry, setSelectCountry] = useState("");
  const [selectService, setSelectService] = useState("");
  const [selectProduct, setSelectProduct] = useState("");

  useEffect(() => {
    const getFilteredListing = async () => {
      setIsLoading(true);

      try {
        // Get the list of sales partner countries
        const countriesUrl = `${pluginGlobals.restUrl}/partner_countries`; // eslint-disable-line
        const countriesObject = await axios(countriesUrl);
        // Parse the array-like object to array containing the slug and the display text
        partnerCountries = Object.keys(countriesObject.data).map(key => {
          return ({slug: key, name: countriesObject.data[key]})});
        // Remove the null element (ACF select has null/please select as a first option)
        partnerCountries.shift();

        // Add the countries as keys to the object and partner array
        // for storing the partner data per country
        partnerCountries.forEach(country => {
          if (country.slug !== 'unitedstatesofamerica') {
            localPartnerList[country.slug] = { partners: [] };
          } else {
            // Add USA and the states under it as keys
            localPartnerList.unitedstatesofamerica = { count: 0 };
            usStates.forEach(state => {
              localPartnerList.unitedstatesofamerica[state.value] = { partners: [] };
            });
          }
        });
      } catch (error) {
        console.error(error.message);
        setIsError(error.message);
      }

      try {
        // Get all the partners and their information
        const configUrl = `${pluginGlobals.restUrl}/filter_partners`; // eslint-disable-line
        const filteredPartners = await axios(
          configUrl, {
            // Add the filter selections as parameters
            params: {
              country: selectCountry,
              service: selectService,
              product: selectProduct
            }
          }
        );
        // Parse the sales partner data array to the object per country
        filteredPartners.data.forEach(partner => {
          partner.fields.sales_partners_country.forEach(country => {
            /**
             * Notice that the country.country value of 'null' is a "placeholder" value for the
             * ACF dropdown field. It is not a valid country and should be ignored.
             */

            if (country.country !== 'null' && localPartnerList[country.country] === undefined) {
              /**
               * Error handling for missing country in ACF field JSON
               *
               */
              console.error(`Error when organizing data in Sales partners: The country ${country.country} is not found in the data fetched from the ACF field list of values for countries. This is most likely because of the JSON file of the ACF field list of values for countries is not up to date. Please update the JSON file by saving the ACF field settings. This country is now ignored in the sales partner list.`);
            } else {
              if (country.country !== 'null' && country.country !== 'unitedstatesofamerica') {
                const partnerObject = {
                  partner: partner.partner,
                  website: partner.fields.website,
                  country
                }
                localPartnerList[country.country].partners.push(partnerObject);
                // If the partner operates in USA add the partner under a state
              } else if (country.country !== 'null' && country.country === 'unitedstatesofamerica') {
                country.state.forEach(state => {
                  const partnerObjectUs = {
                    partner: partner.partner,
                    website: partner.fields.website,
                    country: { ...country, state: state }
                  }
                  if (state !== 'null') {
                    localPartnerList.unitedstatesofamerica.count = localPartnerList.unitedstatesofamerica.count + 1;
                    localPartnerList.unitedstatesofamerica[state].partners.push(partnerObjectUs);
                  }
                })
              }
            }
          })
        });
      } catch (error) {
        console.error(error.message);
        setIsError(error.message);
      }

      setCountryList(partnerCountries);
      setPartnerList(localPartnerList);
      setIsLoading(false);
    };

    getFilteredListing();
  }, [selectCountry, selectService, selectProduct]);

  return (
    <div>
      <SalesPartnerFilters
        countryList={countryList}
        disabled={isLoading}
        setSelectCountry={setSelectCountry}
        setSelectService={setSelectService}
        setSelectProduct={setSelectProduct}
      />
      <SalesPartnerList
        countryList={countryList}
        partnerList={partnerList}
        isLoading={isLoading}
        isError={isError}
        selectCountry={selectCountry}
      />
    </div>
  );
}

export default App;
